import { Injectable } from '@angular/core';

import {
  AccountsService,
  AssetsService,
  FoldersDataService,
  ObservationService,
  RolesService,
  SettingsService,
  ShiftService,
  TeamsService,
  UserService,
} from '@services';

import { TranslateService } from '@ngx-translate/core';
import { chain, cloneDeep, filter, find, get, includes, meanBy, round } from 'lodash';
import { Observation } from '@services/observations/observation.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ObservationSummaryModelService {

  private columnOptions = [
    {
      id: 'count',
      description: this.translate.instant('REPORTING.Service_Number_of_Observations'),
      label: this.translate.instant('REPORTING.Service_label0'),
      units: 'Observations',
      cellType: 'number',
      showWhen: true,
      func: (collection) => collection.length
    },
    {
      id: 'fixtime',
      description: this.translate.instant('REPORTING.Service_Average_Time_to_Fix'),
      label: this.translate.instant('REPORTING.Service_label1'),
      units: 'Days',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        // which ones are fixable?
        const c: any = filter(collection, (o: any): any => {
          if (includes(['ca', 'ai'], o.type)) {
            return find(o.history, (o) => {
              return o.activity === 'fixed' || o.activity === 'resolved';
            });
          } else {
            return includes(['quality', 'condition', 'pi'], o.type) && find(o.history, (o) => {
              return o.activity === 'fixed' || o.activity === 'dropped';
            });
          }
        });
        const m = meanBy(c, (o: any) => {
          const s = this.observations.whenCreated(o);
          const e = this.observations.whenFixed(o);
          return e - s;
        });
        return this.toDays(m, 1);
      }
    },
    {
      id: 'unassignedtime',
      description: this.translate.instant('REPORTING.Service_Average_Time_Unassigned'),
      label: this.translate.instant('REPORTING.Service_Unassigned_label'),
      units: 'Days',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        // which ones are fixable?
        const c: any = filter(collection, (o: any): any => includes(['quality', 'condition', 'pi', 'ca'], o.type) && find(o.history, function (o) {
          return o.activity === 'escalated';
        }));
        const m = meanBy(c, (o: any) => {
          const s = this.observations.unassignedTime(o);
          return s;
        });
        return this.toDays(m, 1);
      }
    },
    {
      id: 'opentime',
      description: this.translate.instant('REPORTING.Service_Average_Time_Open'),
      label: this.translate.instant('REPORTING.Service_label2'),
      units: 'Days',
      showWhen: true,
      func: (collection) => {
        // which ones are fixable?
        const c = filter(collection, (o: any) =>
          includes(['quality', 'condition', 'ca', 'pi'], o.type) // && find(o.history, { 'activity': 'fixed' });
        );
        let m = meanBy(c, (o) => this.observations.openTime(o));
        if (isNaN(m)) {
          m = 0;
        }
        return this.toDays(m, 1);
      }
    },
    {
      id: 'severity',
      description: this.translate.instant('REPORTING.Service_Average_Severity'),
      label: this.translate.instant('REPORTING.Service_label3'),
      cellType: 'number',
      showWhen: true,
      func: (collection) => chain(collection)
        .filter({type: 'condition'})
        .meanBy('severity')
        .round(0)
        .value()
    },
    {
      id: 'likelihood',
      description: this.translate.instant('REPORTING.Service_Average_Likelihood'),
      label: this.translate.instant('REPORTING.Service_label4'),
      cellType: 'number',
      showWhen: true,
      func: (collection) => chain(collection)
        .filter({type: 'condition'})
        .meanBy('likelihood')
        .round(0)
        .value()
    },
    {
      id: 'impact',
      description: this.translate.instant('REPORTING.Service_Average_Impact'),
      label: this.translate.instant('REPORTING.Service_label5'),
      cellType: 'number',
      showWhen: true,
      func: (collection) => chain(collection)
        .filter((observation: any) => includes(['condition', 'quality', 'pi'], observation.type) && observation.state === 'resolved')
        .meanBy('impact')
        .round(1)
        .value()
    },
    {
      id: 'avgTimeToClose',
      description: this.translate.instant('REPORTING.Average_Time_to_Close'),
      label: this.translate.instant('REPORTING.Average_Time_to_Close'),
      units: 'Days',
      cellType: 'number',
      showWhen: true,
      func: (collection) => {
        const c: any = filter(collection, (o: any): any => find(o.history, function (o) {
          return o.activity === 'resolved';
        }));
        const m = meanBy(c, (o: any) => {
          const s = this.observations.whenCreated(o);
          const e = this.observations.whenClosed(o);
          return e - s;
        });
        return this.toDays(m, 1);
      }
    }
  ];
  private fieldOptions = [
    {
      id: 'categories',
      description: this.translate.instant('SHARED.Category'),
      label: this.translate.instant('SHARED.Category'),
      fieldName: 'categories',
      fieldType: 'array',
      fieldRequired: true,
      fieldFunc: (val, ref) => {
        let l = null;
        let b = null;
        let caCategoryType = null;

        if (val === null) {
          val = 0;
          l = 'None Assigned';
        } else {

          if (ref.type === 'ca') {
            caCategoryType = ref.caCategory.split(':')[0];
            val = +ref.caCategory.split(':')[1];
          }

          if (ref.type === 'condition' || caCategoryType === 'condition') {
            b = this.settingsService.categories.data;
          } else if (ref.type === 'behavior' || caCategoryType === 'behavior') {
            b = this.settingsService.behaviors.data;
          } else if (ref.type === 'compliment' || caCategoryType === 'compliment') {
            b = this.settingsService.compliments.data;
          } else if (ref.type === 'quality' || caCategoryType === 'quality') {
            b = this.settingsService.qualityCats.data;
          } else if (ref.type === 'pi' || caCategoryType === 'pi') {
            b = this.settingsService.piCats.data;
          }
          const r: any = find(b, {messageID: val});
          if (r) {
            l = r.messageTitle;
          } else {
            return null;
          }
        }
        return [val, l];
      }
    },
    {
      id: 'type',
      description: this.translate.instant('SHARED.Observation_Type'),
      label: 'Type',
      fieldName: 'type',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        let label = val;
        if (val === 'condition') {
          label = this.translate.instant('SHARED.Unsafe_Condition');
        } else if (val === 'quality') {
          label = this.translate.instant('SHARED.Quality');
        } else if (val === 'pi') {
          if (ref.subtype === 'waiting') {
            val = 'pi:waiting';
            label = this.translate.instant('SHARED.Waiting');
          } else {
            val = 'pi:general';
            label = this.translate.instant('SHARED.General_Improvement');
          }
        } else if (val === 'compliment') {
          label = this.translate.instant('SHARED.Thumbs-Up');
        } else if (val === 'behavior') {
          label = this.translate.instant('SHARED.Coaching_Opportunity');
        } else if (val === 'ca') {
          label = this.translate.instant('SHARED.Corrective_Action');
        } else if (val === 'si') {
          label = this.translate.instant('SHARED.Opportunity');
        } else if (val === 'ai') {
          label = this.translate.instant('SHARED.Asset_Issues');
        }

        return [val, label];
      }
    },
    {
      id: 'state',
      description: this.translate.instant('SHARED.Status'),
      label: this.translate.instant('SHARED.Status'),
      fieldName: 'state',
      fieldType: 'string',
      fieldFunc: (val, ref) => {
        let label = val;
        if (ref.type === 'pi' || ref.type === 'coaching' || ref.type === 'compliment' || ref.type === 'behavior') {
          val = 'closed';
          label = this.translate.instant('SHARED.Closed');
        } else {
          if (val === 'new' || val === 'escalated') {
            val = 'open';
            label = this.translate.instant('SHARED.Open');
          } else if (val === 'workorder') {
            label = this.translate.instant('SHARED.Work_Order');
          } else if (val === 'fixed') {
            label = this.translate.instant('SHARED.Fixed');
          } else if (val === 'resolved') {
            label = this.translate.instant('SHARED.Closed');
          }
        }
        return [val, label];
      }
    },
    {
      id: 'zone',
      description: this.translate.instant('SHARED.Zone'),
      label: this.translate.instant('SHARED.Zone'),
      fieldName: 'zoneID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        // find the zone in the location
        const zsig = ref.locationID + ':' + ref.zoneID;
        const locRef = this.userService.findLocation(ref.locationID);
        const zoneRef = this.userService.findAnyZone(locRef, ref.zoneID);
        if (zoneRef) {
          return [zsig, `${locRef.name}: ${zoneRef.name}`];
        } else {
          if (locRef) {
            return [zsig, locRef.name + '/Site-wide'];
          } else {
            return [zsig, 'Site-wide'];
          }
        }
      }
    },
    {
      id: 'creator',
      description: this.translate.instant('SHARED.Creator'),
      label: this.translate.instant('SHARED.Creator'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val) => [val, this.userService.getFullname(val)]
    },
    {
      id: 'creatorRole',
      description: this.translate.instant('SHARED.Creator_Role'),
      label: this.translate.instant('SHARED.Creator_Role'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        const roles = this.accountsService.getByID(val).roles;
        if (roles?.length) {
          const role = ref?.roles?.length ? ref?.roles[0] : roles[0];
          return [role, get(this.roleService.getRoleById(role), 'name')];
        } else {
          return [0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'creatorTeam',
      description: this.translate.instant('SHARED.Creator_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val) => {
        const u = this.accountsService.getAccount(val);
        if (u.primaryGroup) {
          return [u.primaryGroup, this.teamsService.teamNameByID(u.primaryGroup)];
        } else if (u.groups && u.groups[0]) {
          return [u.groups[0], this.teamsService.teamNameByID(u.groups[0])];
        } else {
          return [0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'owner',
      description: this.translate.instant('SHARED.Helper'),
      label: this.translate.instant('SHARED.Helper'),
      fieldName: 'ownerID',
      fieldType: 'integer',
      fieldFunc: (val) => {
        let n = 'unassigned';
        if (val) {
          n = this.userService.getFullname(val);
        }
        return [val, n];
      }
    },
    {
      id: 'ownerTeam',
      description: this.translate.instant('SHARED.Helper_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'ownerID',
      fieldType: 'integer',
      fieldFunc: (val) => {
        const u = this.accountsService.getAccount(val);
        if (u.primaryGroup) {
          return [u.primaryGroup, this.teamsService.teamNameByID(u.primaryGroup)];
        } else if (u.groups && u.groups[0]) {
          return [u.groups[0], this.teamsService.teamNameByID(u.groups[0])];
        } else {
          return [0, this.translate.instant('SHARED.None')];
        }
      }
    },
    {
      id: 'locationID',
      description: this.translate.instant('SHARED.Location'),
      label: 'Location',
      fieldName: 'locationID',
      fieldType: 'number',
      fieldFunc: (locationId: number) => {
        let locationName: string = get(this.userService.findLocation(locationId), 'name');

        if (!locationName) {
          locationId = 0;
          locationName = this.translate.instant('DASHPAGES.NA');
        }
        return [locationId, locationName];
      }
    },
    {
      id: 'fixer',
      description: this.translate.instant('SHARED.Fixer'),
      label: this.translate.instant('SHARED.Fixer'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        let v = 0;
        let n = 'n/a';
        let p: any;
        if (ref.type === 'quality') {
          if (ref.subtype === 'receiving') {
            // receiving just go straight to closed; use the creator
            p = find(ref.history, {activity: 'created'});
          } else if (ref.subtype === 'production') {
            // production can go to dropped; pretend that is 'fixed'
            p = find(ref.history, {activity: 'fixed'});
            if (!p) {
              p = find(ref.history, {activity: 'dropped'});
            }
          }
        } else if (ref.type === 'pi') {
          if (ref.subtype === 'waiting') {
            p = find(ref.history, {activity: 'created'});
          } else {
            p = find(ref.history, {activity: 'fixed'});
          }
        } else {
          p = find(ref.history, {activity: 'fixed'});
        }

        // we still have no pointer - let's look for resolved
        if (!p) {
          p = find(ref.history, {activity: 'resolved'});
        }

        if (p) {
          v = p.userID;
          n = this.userService.getFullname(p.userID);
        }
        return [v, n];
      }
    },
    {
      id: 'fixerTeam',
      description: this.translate.instant('SHARED.Fixer_Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        let v = val;
        let n = 'n/a';
        let p: any;
        if (ref.type === 'quality') {
          if (ref.subtype === 'receiving') {
            // receiving just go straight to closed; use the creator
            p = find(ref.history, {activity: 'created'});
          } else if (ref.subtype === 'production') {
            // production can go to dropped; pretend that is 'fixed'
            p = find(ref.history, {activity: 'fixed'});
            if (!p) {
              p = find(ref.history, {activity: 'dropped'});
            }
          }
        } else if (ref.type === 'pi') {
          if (ref.subtype === 'waiting') {
            p = find(ref.history, {activity: 'created'});
          } else {
            p = find(ref.history, {activity: 'fixed'});
          }
        } else {
          p = find(ref.history, {activity: 'fixed'});
        }

        // we still have no pointer - let's look for resolved
        if (!p) {
          p = find(ref.history, {activity: 'resolved'});
        }
        if (p) {
          v = p.userID;
          const u = this.accountsService.getAccount(v);
          if (u.primaryGroup) {
            return [u.primaryGroup, this.teamsService.teamNameByID(u.primaryGroup)];
          } else if (u.groups && u.groups[0]) {
            return [u.groups[0], this.teamsService.teamNameByID(u.groups[0])];
          } else {
            return [0, this.translate.instant('SHARED.None')];
          }
        }
        return [v, n];
      }
    },
    {
      id: 'closer',
      description: this.translate.instant('SHARED.Closer'),
      label: this.translate.instant('SHARED.Closer'),
      fieldName: 'userID',
      fieldType: 'integer',
      fieldFunc: (val, ref) => {
        let v = val;
        let n = 'n/a';
        const p: any = find(ref.history, {activity: 'resolved'});
        if (p) {
          v = p.userID;
          n = this.userService.getFullname(p.userID);
          return [v, n];
        }
      }
    },
    {
      id: 'team',
      description: this.translate.instant('SHARED.Team'),
      label: this.translate.instant('SHARED.Team'),
      fieldName: 'groupID',
      fieldType: 'integer',
      fieldFunc: (val) => [val, this.teamsService.teamNameByID(val)]
    },
    {
      id: 'tag',
      description: this.translate.instant('SHARED.Tag'),
      label: this.translate.instant('SHARED.Tag'),
      fieldName: 'tags',
      fieldType: 'array',
      fieldFunc: (val) => {
        const tag: any = get(find(this.settingsService.customTags.data, <any>{tagID: +val}), 'tag', null);
        return tag ? [val, tag] : null;
      }
    },
    {
      id: 'creator_shift',
      description: this.translate.instant('SHARED.Creator_Shift'),
      label: this.translate.instant('SHARED.Creator_Shift'),
      fieldName: 'creatorShift',
      fieldType: 'string',
      fieldFunc: (val) => [val, this.shift.name(val)]
    },
    {
      id: 'helper_shift',
      description: this.translate.instant('SHARED.Helper_Shift'),
      label: this.translate.instant('SHARED.Helper_Shift'),
      fieldName: 'ownerShift',
      fieldType: 'string',
      fieldFunc: (val) => [val, this.shift.name(val)]
    },
    {
      id: 'assets',
      description: this.translate.instant('SHARED.Asset'),
      label: this.translate.instant('SHARED.Asset'),
      fieldName: 'subjectType',
      fieldType: 'string',
      fieldFunc: (subjectType: string, observation: Observation) => {
        if (subjectType === 'asset') {
          const cellLabel = this.assetsService.getAssetById(observation?.subjectID)?.[0]?.name || this.translate.instant('SHARED.None');
          return [observation?.subjectID, cellLabel];
        }
      }
    },
    {
      id: 'assetsFolder',
      description: this.translate.instant('MGMT_LIST.Asset_Folder'),
      label: this.translate.instant('MGMT_LIST.Asset_Folder'),
      fieldName: 'subjectType',
      fieldType: 'string',
      fieldFunc: (subjectType: string, observation: Observation) => {
        if (subjectType === 'asset') {
          const asset = this.assetsService.getAssetById(observation?.subjectID)?.[0];

          if (asset) {
            const cellLabel = this.foldersDataService.getFolderByID(asset.folderID)?.title || this.translate.instant('SHARED.None');
            return [observation?.subjectID, cellLabel];
          }
        }
      }
    },
  ];

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private accountsService: AccountsService,
    private shift: ShiftService,
    private teamsService: TeamsService,
    private settingsService: SettingsService,
    private observations: ObservationService,
    private roleService: RolesService,
    private assetsService: AssetsService,
    private foldersDataService: FoldersDataService
  ) {}

  public getColumns() {
    const columns = filter(cloneDeep(this.columnOptions), (opt) => opt.showWhen);
    return columns;
  }

  public getFieldOptions() {
    return this.fieldOptions;
  }

  private toDays(seconds, precision: number = 0) {
    let f = seconds;
    if (f) {
      f = round(f / (60 * 60 * 24), precision);
    }
    return f || 0;
  }

}
